.wrapper {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 7px 0 0 0;
    font-size: var(--font-size-smaller);
}

.wrapper a, .wrapper li:nth-child(2), .wrapper li:nth-child(4) {
    margin: 0 6px;
}





@media screen and (max-width: 750px) {
    .wrapper {
        flex-direction: column;
        margin: 6px 0;
    }

    .wrapper li:nth-child(2), .wrapper li:nth-child(4) {
        display: none;
    }

    .wrapper li:nth-child(3) {
        margin: 6px 0;
    }
}


@media screen and (max-width: 450px) {
    .wrapper {
        display: none;
    }

}