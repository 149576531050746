.wrapper {
    display: flex;
    flex-direction: column;
}



.middle {
    display: flex;
}

.left {
    width: 50%;
    overflow-y: auto;
    display: none;
    height: calc(100vh - 107px);
}

.left.visible {
    display: block;
}


.tiles {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 15px;
}

.right {
    width: 50%;
    display: none;
}

.right.visible {
    display: block;
}

.mobile {
    display: none;
}


.browse {
    height: calc(100vh - 130px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}

.browse .button {
    margin-top: 30px;
    padding: 15px 30px;
    border-radius: 30px;
    border: 1px solid var(--color-txt-accent);

    transition: 0.3s all ease;
}

.browse .button:hover {
    box-shadow: 0 0 0 1px var(--color-txt-accent);
}



/* mobile view toggle button */
.toggle {
    display: none;

}



@media screen and (max-width: 750px) {



    .toggle {
        display: block;

        position: fixed;
        bottom: 21px;
        right: calc(50% - 30px);
    
        padding: 6px 12px;
        border-radius: 18px;
        background-color: var(--color-bgd-secondary);
        border: 1px solid var(--color-bgd-tertiary);
        transition: all 0.2s ease;
        color: var(--color-txt-primary);

        display: flex;
        align-items: center;
    
        z-index: 5;
    }

    .toggle span {
        margin-top: 2px;
    }



    .wrapper {
        flex-direction: column-reverse;
    }



    .left {
        width: 100%;
        overflow-y: scroll;
        height: calc(100vh + 69px);
    }

    .tiles {
        font-size: var(--font-size-smaller);
        width: 100%;
    }


    .right {
        width: 100%;
    }




}