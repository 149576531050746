.spinner {
   height: calc(100vh - 270px);
   width: 100%;
}


.spinner>svg {
   display: block;
   margin: 24vh auto 0;
   
   color: var(--color-app-primary);
}