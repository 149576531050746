/* App */
.AppFrame {
    min-height: max(100vh, 600px);
    display: block;
    background-color: var(--color-bgd-primary);
    color: var(--color-txt-primary);
}

.AppHeader {
    display: flex;
    align-items: center;
    justify-content: space-around;

    position: fixed;
    top: 0;
    width: 100%;

    background-color: rgba(var(--color-bgd-number), 0.69);
    backdrop-filter: blur(18px);

    z-index: 4;
}


/* account menu dialog */
.AppHeader>div:last-child {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: var(--color-app-primary);
    border: 1px solid var(--color-bgd-tertiary);
    font-size: var(--font-size-medium);
    transition: all 0.1s ease;

}

.AppHeader>div:last-child:hover, .AppHeader>div:last-child:active {
    box-shadow: 0 0 0 1px var(--color-bgd-tertiary);
}


.AppHeader.cover {
    padding: 15px 0;
}


.AppHeader.platform {
    padding: 15px 0 9px 0;
}

.AppHeader.detail {
    padding: 15px 0;
}

.AppHeader.account {
    justify-content: center;
    padding-top: 30px;
}


.AppFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 36px 0;
    width: 100%;
}

.AppFooter.account {
    position: absolute;
    bottom: 0;
}


.AppFrame.cover {
    padding-top: 162px;
}

.AppFrame.platform {
    padding-top: 130px;
}

.AppFrame.detail {
    padding-top: 90px;
}




@media screen and (max-width: 1200px) {
    .AppFooter {
        flex-direction: column;
        margin-top: 6px;
        padding: 24px 0;
    }
}

@media screen and (max-width: 900px) {
    .AppFrame.platform {
        padding-top: 159px;
    }
}




@media screen and (max-width: 750px) {
}




@media screen and (max-width: 720px) {

    .AppFrame.platform {
        padding-top: 107px;
    }
}

@media screen and (max-width: 600px) {
    .AppFrame.cover {
        padding-top: 144px;
    }
}

@media screen and (max-width: 564px) {
    .AppHeader.platform {
        padding: 12px 0 6px 0;
    }

}