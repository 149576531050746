.wrapper {
    display: none;
    background-color: var(--color-app-lighter);
    border-radius: 9px;
    padding: 15px;
}

/* animation total time 3s */
.wrapper.visible {
    display: block;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}