.wrapper {
    position: fixed;
    top: 75px;
    width: 100%;

    display: flex;
    align-items: center;


    border-bottom: 1px solid var(--color-bgd-tertiary);

    background-color: rgba(var(--color-bgd-number), 1);
    backdrop-filter: blur(18px);

    z-index: 3;

    padding: 9px 0;
    font-size: var(--font-size-small);

}

/* segment */
.segment {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-between;
}

.segment:first-child {
    margin: 0 15px 0 45px;
}

.segment:last-child {
    margin: 0 30px;
}





/* scrollable */
.scrollable {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    /* overflow-x: scroll; */
}




/** filter **/
.filter {
    min-width: 540px;

    display: flex;
    align-items: center;

    padding: 3px 0;
}

.filter>span {
    margin-right: 6px;
    color: var(--color-txt-tertiary);
    font-weight: 300;
}

.filter ul {
    display: flex;
}

.filter ul .button {
    margin: 0 3px;
}

.filter ul .button span {
    padding: 6px 12px;
    border-radius: 18px;
    background-color: var(--color-bgd-primary);
    border: 1px solid var(--color-bgd-accent);
    transition: all 0.2s ease;

    display: block;
    /* width: 100%; */
}

.filter ul .button span:hover {
    box-shadow: var(--color-bgd-accent) 0px 0px 0px 1px;
    cursor: pointer;
}

.filter ul .button input {
    display: none;
}

.filter ul .button input[value='true']+span {
    background-color: var(--color-bgd-accent);
    border: 1px solid var(--color-bgd-accent);
}



/* area search */
.area {
    min-width: 145px;
}

.area .button {
    padding: 6px 12px;
    border-radius: 18px;
    background-color: var(--color-bgd-primary);
    border: 1px solid var(--color-bgd-tertiary);
    transition: all 0.2s ease;

    color: var(--color-txt-primary);

    display: flex;
    align-items: center;
}


.area:hover .button {
    box-shadow: 0px 0px 0px 1px var(--color-bgd-tertiary);
}

.area:active .button, .area:focus .button {
    box-shadow: 0px 0px 0px 1px var(--color-txt-accent);
    border-color: var(--color-txt-accent);
}


.area .button>span:first-child, .sort .button>span:first-child {
    font-size: var(--font-size-primary);
    color: var(--color-app-medium);
    margin-right: 6px;
    margin-left: -3px;
}

.area .button>span:last-child, .sort .button>span:last-child {
    font-size: var(--font-size-small);
}



/** sort **/
.sort {
    min-width: 170px;

    display: flex;
    align-items: center;


    position: relative;
    z-index: 2;
}

.sort>span {
    margin: 0 9px 0 0;
    color: var(--color-txt-tertiary);
    font-weight: 300;
}


.sort .button {
    padding: 6px 12px;
    border-radius: 18px;
    background-color: var(--color-bgd-primary);
    border: 1px solid var(--color-bgd-tertiary);
    transition: all 0.2s ease;

    color: var(--color-txt-primary);

    display: flex;
    align-items: center;
}

.sort:hover .button {
    box-shadow: 0px 0px 0px 1px var(--color-bgd-tertiary);
}

ul.expandable {
    display: none;
    position: absolute;
    min-width: 120px;
    background-color: var(--color-bgd-primary);
    box-shadow: 0px 0px 3px 1px var(--color-bgd-tertiary);
    border-radius: 9px;
    margin: 6px auto;
    right: 0;
    top: 36px;

    z-index: 999;
}

ul.expandable.visible {
    display: block;
}

ul.expandable li {
    color: var(--color-txt-primary);
    padding: 9px 15px;
    display: block;
}

ul.expandable li.selected {
    font-weight: bold;
}

ul.expandable li:first-child {
    margin-top: 9px;
}

ul.expandable li:last-child {
    margin-bottom: 9px;
}

/* change color of dropdown links on hover, active on mobile */
ul.expandable li:hover, ul.expandable li:active {
    background-color: var(--color-bgd-secondary);
    cursor: pointer;
}




/* arrows */
.arrow {
    border: none;
    display: none;
    position: absolute;

    background-color: var(--color-bgd-primary);
    border: 1px solid var(--color-bgd-tertiary);
    box-shadow: 0 0 3px 0 var(--color-bgd-tertiary);

    height: 21px;
    width: 21px;
    border-radius: 50%;

    z-index: 3;
}

.arrow span {
    font-size: var(--font-size-primary);
}

.arrow:hover>span {
    cursor: pointer;
}

.arrow.visible {
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow:first-child {
    left: 9px;
}

.arrow:last-child {
    right: 9px;
}

@media screen and (max-width: 1200px) {
    .scrollable {
        justify-content: center;
    }

    .segment {
        width: 100%;
    }

    .segment:first-child {
        margin: 0 0 0 30px;
    }

    .segment:last-child {
        justify-content: flex-end;
    }

    .area {
        margin-right: 30px;
    }
}

@media screen and (max-width: 975px) {
    .wrapper {
        flex-direction: column;
        align-items: center;
    }

    .segment:first-child {
        justify-content: center;
        margin: 0 0 6px 0;
    }

    .segment:last-child {
        justify-content: center;
        flex-direction: row-reverse;
    }

    .area {
        margin-right: 0;
    }

    .sort {
        margin-right: 30px;
    }
}


@media screen and (max-width: 750px) {
    .wrapper {
        flex-direction: row;
        top: 66px;
        font-size: var(--font-size-smaller);
    }

    .segment:first-child {
        margin: 0 12px 0 36px;
        left: 36px;
    }

    .segment:last-child {
        margin: 0 36px 0 0;
        justify-content: flex-start;
    }

    .scrollable {
        overflow-x: auto;
        justify-content: flex-start;
    }

    .filter {
        min-width: 485px;
    }

    .sort {
        min-width: 152px;
        margin-right: 0;
    }

    .area {
        min-width: 131px;
        margin-right: 0;
    }

    .area .button>span:first-child, .sort .button>span:first-child {
        font-size: var(--font-size-small);
    }

    .area .button>span:last-child, .sort .button>span:last-child {
        font-size: var(--font-size-smaller);
    }

}


@media screen and (max-width: 720px) {
    .segment:first-child {
        margin: 0 0 0 15px;
    }

    .segment:last-child {
        margin: 0 15px;
    }

    .filter>span, .sort>span {
        display: none;
    }

    .filter {
        min-width: 415px;
    }

    .sort {
        min-width: 104px;
    }
}

@media screen and (max-width: 564px) {
    .wrapper {
        justify-content: flex-end;
        top: 60px;
        padding: 6px 0;
    }

    .scrollable {
        justify-content: center;
    }

    .segment:last-child {
        position: fixed;
        top: 54px;
        right: 6px;
    }

}

@media screen and (max-width: 434px) {
    .scrollable {
        justify-content: flex-start;
        width: 414px;
        padding: 0 30px;
    }

    .scrollable:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
        pointer-events: none;
        background-image: linear-gradient(to right,
                rgba(255, 255, 255, 0),
                rgb(var(--color-bgd-number)) 90%);
        width: 30px;
        height: 100%;
    }


    .scrollable:before {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(to left,
                rgba(255, 255, 255, 0),
                rgb(var(--color-bgd-number)) 90%);
        width: 30px;
        height: 100%;
    }
}