.wrapper {
    display: flex;
    align-items: center;
    padding-top: 7px;
    min-width: 210px;
    margin: 0 30px;
    font-size: var(--font-size-smaller);
}

.wrapper>span {
    margin-right: 9px;
}

/* the box around the slider */
.toggle {
    position: relative;
    display: inline-block;
    width: 33px;
    height: 24px;
}

/* hide default html checkbox */
.toggle .checkbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-txt-accent);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: var(--color-bgd-primary);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.checkbox:checked+.slider {
    background-color: var(--color-txt-accent);
}

.checkbox:focus+.slider {
    box-shadow: 0 0 1px var(--color-txt-accent);
}

.checkbox:checked+.slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
}

.slider.round {
    border-radius: 24px;
}

.slider.round:before {
    border-radius: 50%;
}

@media screen and (max-width: 1200px) {
    .wrapper>span {
        margin-right: 6px;
    }

    .toggle {
        transform: scale(0.75);
    }
}