.wrapper {
    display: flex;
    align-items: flex-end;
}

.wrapper:hover {
    cursor: pointer;
}

.wrapper img:first-child {
    height: 51px;
}




@media screen and (max-width: 750px) {
    .wrapper {
        flex-direction: column;
        align-items: center;
    }

    .wrapper img:first-child {
        width: 36px;
        padding: 0 6px;
    }


}


@media screen and (max-width: 480px) {

    .wrapper img:first-child {
        height: 36px;
    }

    .wrapper h2 {
        display: none;
    }
}