.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}



.top {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    min-height: 30vh;
    background-color: var(--color-bgd-secondary);
    padding: 60px;
    border-radius: 15px;

    margin: 9px 0 36px;

}



.top .icon {
    font-size: var(--font-size-large);
    padding: 12px;
    /* color: var(--color-app-light); */
}

.icon:first-child {
    transform: rotate(150deg);
    color: var(--color-app-medium);
}

.icon:nth-child(2) {
    color: var(--color-app-light);
}

.icon:last-child {
    transform: rotate(120deg);
    color: var(--color-app-primary);
}

.left, .right {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    width: 15%;
}

.middle{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 36px;
    text-align: center;
}


.middle h3{
    color: var(--color-app-medium);
    font-size: xx-large;

}

.middle p {
    color: var(--color-txt-primary);
}


.middle h5{
    color: var(--color-txt-tertiary);
    font-size: medium;
    margin-top: 45px;
}


.wrapper ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.wrapper li {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper img {
    border-radius: 15px;
}


.top img {
    width: 90%;
    object-fit: cover;
    max-width: 1500px;
    margin-bottom: 18px;

}

.bottom img {
    width: 90%;
    object-fit: cover;
    max-width: 900px;
}


.middle>a {
    /* position: absolute;
    bottom: 6%;
    left: 50%;
    transform: translate(-50%, -50%); */

    background-color: var(--color-app-primary);
    color: var(--color-lightest);
    padding: 15px 30px;
    border-radius: 30px;
    font-size: var(--font-size-primary);
    margin-top: 36px;
    transition: all 0.3s ease;
}

.middle>a:hover {
    box-shadow: 0px 0px 3px 0px var(--color-bgd-primary);
    background-color: var(--color-app-medium);
}



.bottom img {
    margin-top: 18px;
}

.fourCols li{
    position: relative;
    margin-bottom: 30px;
}

.fourCols span {
    position: absolute;
    bottom: 0;
    left: 12px;
    margin: auto;
    width: 21vw;

    font-size: var(--font-size-primary);
    font-weight: 500;
    color: var(--color-bgd-primary);
    background-color: var(--color-app-medium);
    padding: 12px 0;
    border-radius: 0 0 18px 18px;
    text-align: center;
}


.twoCols li{
    position: relative;
}
.twoCols span {
    position: absolute;

    font-size: 45px;
    font-weight: 600;
    padding: 12px 24px;
    border-radius: 18px;
}

.twoCols span:first-child {
    text-align: left;
    left: 0;
    top: 66%;
    color: var(--color-lightest);
}
.twoCols span:last-child {
    text-align: left;
    left: 15px;
    top: 15px;
    color: var(--color-lightest);
}





.fourCols li img {
    height: 21vw;
    width: 21vw;
    object-fit: cover;
    margin: 0 12px;
}

.twoCols li img {
    height: 42vw;
    width: 42vw;
    object-fit: cover;
    margin: 0 12px;

    max-width: 600px;
}


@media screen and (max-width: 900px) {
    .top {
        padding: 12px;
        width: 85%;
    }
    
    .top>a {
        padding: 9px 21px;
        border-radius: 18px;
        font-size: var(--font-size-small);
    }
}

@media screen and (max-width: 750px) {

    .fourCols span, .twoCols span {
        font-size: var(--font-size-smaller);  
        padding: 6px 0;
    }

    .fourCols {
        flex-wrap: wrap;
    }

    .fourCols li img, .twoCols li img {
        height: 36vw;
        width: 45vw;
        margin: 12px 9px;
    }

    .fourCols span {
        width: calc(100% - 18px);
        
        left: 9px;
    }



    .top img, .bottom img {
        width: 100vw;
        border-radius: 0;
        max-height: 75vh;
        object-fit: cover;
    }
}


@media screen and (max-width: 630px) {
}


@media screen and (max-width: 450px) {

    .middle {
        padding: 0 9px;
    }

    .top {
        padding: 12px;
        width: 85%;
    }

    .top>a {
        padding: 6px 21px;
        border-radius: 15px;
        font-size: var(--font-size-smaller);
    }

    .middle a {
        font-size: small;
        padding: 6px 12px;
        margin: 12px auto 18px;
    }

        
    .middle p{
        font-size: small;
    }


    .middle h3{
        font-size: medium;

    }


    .middle h5{
        display: none;
    }


    
    .twoCols span:first-child, .twoCols span:nth-child(2){
        top: 5vw;
        left: 5vw;
    }
}