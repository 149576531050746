.wrapper {
    display: flex;
    align-items: flex-end;
    margin: 0 30px;
    font-size: var(--font-size-smaller);
}

.wrapper img {
    height: 21px;
    margin: 0 3px;
}

.wrapper span a {
    color: var(--color-app-primary);
}

.wrapper span:nth-child(2) {
    margin-left: 6px;
}


@media screen and (max-width: 750px) {
    .wrapper {
        flex-direction: column;
        align-items: center;
    }

    .wrapper span:nth-child(2) {
        margin-left: 0;
        margin-top: 6px;
    }
}